import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";

export const TypeMasterContext = createContext(null);

function TypeMasterProvider({ children }) {
  const [role, setRole] = useState([]);
  console.log(role);
  const [gender, setGender] = useState([]);
  const [educationType, setEducationType] = useState([]);
  console.log(educationType);

  const getRoleType = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/roles/");
      console.log(res.data);
      setRole(
        res.data.data.map((pro) => ({
          value: pro.id,
          label: pro.role_name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getGender = async () => {
    try {
      const res = await axios.get(VARIABLES.url + "/api/typemasterdetails/");
      let dropdownData = res.data.data;
      const genderData = dropdownData?.filter(
        (post) => post.category_name == "gender"
      );
      const eduData = dropdownData?.filter(
        (post) => post.category_name == "education_type"
      );
      setGender(
        genderData.map((pro) => ({
          value: pro.value,
          label: pro.name,
        }))
      );
      setEducationType(
        eduData.map((pro) => ({
          value: pro.value,
          label: pro.name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   getRoleType();
  //   getGender();
  // }, []);

  return (
    <div>
      <TypeMasterContext.Provider value={{ role, gender, educationType, getRoleType, getGender }}>
        {children}
      </TypeMasterContext.Provider>
    </div>
  );
}

export default TypeMasterProvider;
