import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constant";
import { UserDetailsContext } from "../Context/UserDetailsContext";
import Swal from "sweetalert2";
import { useGoogleLogin } from "@react-oauth/google";

const Login = (props) => {
  console.log(props);

  const { getUserDetailsData, setActiveTab } = useContext(UserDetailsContext);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);


  const navigate = useNavigate();

  const handleModalClose = () => {
    reset();
  };

  const onSubmitCandidateHandler = (user_id, userData) => {
    const job_data = secureLocalStorage.getItem("job_opening_data");
    console.log(job_data);
    var candidate_info = JSON.stringify({
      job_opening_id: job_data?.id,
      user_id: user_id,
      notice_period: null,
      current_previous_company: "",
      expected_salary: null,
      experience: null,
      is_draft: true,
      created_by_id: user_id,
      updated_by_id: user_id,
    });

    console.log(candidate_info);

    const url = VARIABLES.url + "/api/candidatetobeprocess/";
    console.log(url);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: candidate_info,
    };
    console.log(candidate_info);
    setIsLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          setIsLoading(false);
          console.log(JSON.stringify(response.data.data));
          secureLocalStorage.setItem("candidate_info", response.data.data.candidate_data.id);
          setSubmitMessage({
            type: "success",
            icon: "check",
            message:
              "Thankyou for choosing the Job, Please complete your Process to apply for the Job",
          });
          props.loginHandler(userData);
          document.body.style.overflow = "auto";
          const modalBackdrop = document.querySelector(".modal-backdrop");
          if (modalBackdrop) {
            modalBackdrop.remove();
          }
          console.log("In");
          setActiveTab(1);
          navigate(`/apply/${job_data?.url_required}`);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setSubmitMessage({
          type: "danger",
          icon: "cross",
          message: "Failed to apply for Job. Please check your network",
        });
      });
  };

  const onSubmit = (data) => {
    let user = new FormData();
    user.append("email", data.email);
    user.append("password", data.password);
    user.append("portal_unique_id", VARIABLES.career_login);
    user.append("is_google_login", "False");

    const url = VARIABLES.url + "/api/login-career-page/";
    console.log(url);

    let reqOptions = {
      url: url,
      method: "post",
      data: user,
    };

    for (var pair of user.entries()) {
      console.log(pair);
    }

    setIsLoading(true);
    axios
      .request(reqOptions)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        const loginStatus = response.status;
        console.log("loginStatus", loginStatus);
        if (loginStatus === 200) {
          const userData = response.data.data[0].user_details;
          setUserDetails(userData);
          secureLocalStorage.setItem("user-details", userData);
          console.log("userData", userData);
          setIsLoading(false);
          getUserDetailsData(userData?.user?.id);
          const login_status = secureLocalStorage.getItem("login_status");
          if (login_status === "form") {
            onSubmitCandidateHandler(userData?.user?.id, userData);
          } else {
            props.loginHandler(userData);
            document.body.style.overflow = "auto";
            const modalBackdrop = document.querySelector(".modal-backdrop");
            if (modalBackdrop) {
              modalBackdrop.remove();
            }
            setSubmitMessage({
              type: "success",
              icon: "check",
              message:
                "Thankyou for choosing the Job, Please complete your Process to apply for the Job",
            });
            navigate("/");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response);
        if (error.response?.status === 401) {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: error?.response?.data?.message || "Something went wrong!",
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: "Oops!",
            text: error?.response?.data?.message || "Something went wrong!",
          });
        }
      });
    handleModalClose();
  };

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      // setUser(codeResponse);
      const user = codeResponse;
      checkGoogleAuthentication(user);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const checkGoogleAuthentication = (user) => {
    console.log(user);
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          let profile = res.data;
          console.log(profile);
          let user = new FormData();
          user.append("email", profile.email);
          user.append("portal_unique_id", VARIABLES.career_login);
          user.append("first_name", profile.given_name);
          user.append("last_name", profile.family_name);
          user.append("is_google_login", "True");

          const url = VARIABLES.url + "/api/login-career-page/";
          console.log(url);

          for (var pair of user.entries()) {
            console.log(pair);
          }

          let reqOptions = {
            url: url,
            method: "POST",
            data: user,
          };

          setIsLoading(true);
          axios
            .request(reqOptions)
            .then((response) => {
              const userData = response.data.data[0].user_details;
              const loginStatus = response.status;
              if (loginStatus === 200) {
                setIsLoading(false);
                props.loginHandler(userData);
              }
            })
            .catch((error) => {
              console.log(error.response);
              setTimeout(() => {
                setIsLoading(false);
                setSubmitMessage({
                  type: "danger",
                  icon: "cross",
                  message: error.response?.data?.message,
                });
              }, 5000);
            });
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSubmitMessage(null);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [submitMessage]);

  return (
    <>
      <div
        className="modal"
        id="loginModal"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 class="modal-title">Sign In to your career account</h4>
              <button
                type="button"
                className="btn-close"
                id="button-button-closeloginmodal"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleModalClose}
              ></button>
            </div>
            <div className="brand-logo text-center">
              <a
                href={VARIABLES.fsc_redirection}
                target="_blank"
                className="logo-link"
                id="button-button-logo"
              >
                <img
                  className="logo-light logo-img logo-img-xl"
                  src="./assets/images/fsc_animated_logo_black.gif"
                  srcSet="./assets/images/fsc_animated_logo_black.gif"
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img logo-img-xl"
                  src="./assets/images/fsc_animated_logo_black.gif"
                  srcSet="./assets/images/fsc_animated_logo_black.gif"
                  alt="logo-dark"
                />
              </a>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label className="form-label">
                        Email <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="email-forms-email"
                        placeholder="Enter your email address"
                        {...register("email", {
                          required: "Email is required",
                          validate: (value) =>
                            value === value.toLowerCase() ||
                            "Email should only contain lowercase letters",
                        })}
                      />
                      {errors.email && (
                        <span className="fs-8 text-danger">
                          {errors?.email?.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="form-label" for="password">
                        Password <span className="text-danger">*</span>
                      </label>
                      <div className="form-control-wrap">
                        <a
                          href="javascript:void(0)"
                          id="button-button-showpassword"
                          onClick={() => setShowPassword(!showPassword)}
                          className={`${showPassword === true
                            ? "form-icon form-icon-right passcode-switch lg is-shown"
                            : "form-icon form-icon-right passcode-switch lg"
                            }`}
                          data-target="password"
                        >
                          <em className="passcode-icon icon-show icon ni ni-eye"></em>
                          <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                        </a>
                        <input
                          type={`${showPassword === true ? "text" : "password"
                            }`}
                          className={`${showPassword === true
                            ? "form-control form-control-lg valid is-shown"
                            : "form-control form-control-lg"
                            }`}
                          placeholder="Enter your password"
                          id="password-forms-password"
                          {...register("password", { required: true })}
                        />
                      </div>
                      {errors.password && (
                        <span className="fs-8 text-danger">
                          Password is required
                        </span>
                      )}
                    </div>
                    <div className="fs-7 pb-3 text-primary">
                      {/* <a
                        href="https://rms-api-test.techwalnut.co.in/password-reset/"
                        target="_blank"
                      >
                        Forgot Password
                      </a> */}
                      <a
                        href="javascript:void(0)"
                        data-bs-target="#passwordreset"
                        data-bs-toggle="modal"
                        id="button-button-forgotpassword"
                      >
                        Forgot Password ?
                      </a>
                    </div>
                    <div className="form-group d-flex justify-content-center">
                      {isLoading ? (
                        <button
                          className="btn text-dark"
                          id="button-button-signoutloading"
                          disabled
                        >
                          Loading...
                        </button>
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-lg btn-block btn-primary"
                          id="submit-button-signin"
                        >
                          SIGN IN
                        </button>
                      )}
                    </div>
                    <div
                      className="form-note-s2 text-center pt-2"
                      style={{ fontSize: "14px" }}
                    >
                      New on our platform?{" "}
                      <a
                        href="javascript:void(0)"
                        data-bs-target="#signup"
                        data-bs-toggle="modal"
                        id="button-button-signup"
                      >
                        Create an account
                      </a>
                    </div>
                  </div>
                </div>
              </form>
              <div className="text-center pt-2 pb-3">
                <h6 className="overline-title overline-title-sap">
                  <span>OR</span>
                </h6>
              </div>
              <ul className="nav justify-center gx-4">
                <li className="nav-item">
                  <button
                    className="btn btn-lg btn-block btn-primary"
                    // href="#"
                    onClick={() => login()}
                    id="button-google-authentication"
                  >
                    Sign in with Google <em className="ni ni-google ms-1"></em>{" "}
                  </button>
                </li>
              </ul>
            </div>
            <div className="modal-footer">
              <div className="nk-block-content text-center">
                <p className="text-soft fs-7">
                  &copy; 2024 Fluidscapes Consultant Pvt.Ltd.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {submitMessage && (
            <div
              className="example-alerts position-fixed bottom-0 end-0 p-3"
              style={{ zIndex: 100 }}
            >
              <div className="gy-4">
                <div className="example-alert">
                  <div
                    className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                  >
                    <em
                      className={`icon ni ni-${submitMessage.icon}-circle`}
                    ></em>
                    <strong>{submitMessage.message}</strong>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
