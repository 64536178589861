import React, { createContext, useContext, useEffect, useState } from "react";
import { WorkflowContext } from "../../../Context/WorkflowContext";
import secureLocalStorage from "react-secure-storage";
// import { candidateFormWorkflow } from '../WorkflowStepperForm/StepperFormJson'

export const SequenceContext = createContext(null);

function SequenceContextProvider({ children }) {
  const { candidateFormWorkflow } = useContext(WorkflowContext);
  const privileges = secureLocalStorage.getItem("privileges");
  // const privileges = [
  //   "Upload Assignment",
  //   "KYC",
  //   "Send Offer",
  //   "Telephonic Interview",
  //   "HR Interview",
  //   "Technical Interview",
  //   "Test",
  //   "Smart Hire Form",
  // ];

  console.log(candidateFormWorkflow);

  const [level, setLevel] = useState(0);
  console.log(level);
  const [sequenceList, setSequenceList] = useState([]);
  console.log("sequenceList: ", sequenceList);
  const [currentSequence, setCurrentSequence] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const [steppers, setSteppers] = useState([]);
  const [currentForm, setCurrentForm] = useState(null);
  console.log(currentForm);

  const [filteredTabs, setFilteredTabs] = useState([]);

  const filterTabsWithForms = (tabs) => {
    return tabs?.flatMap(tab => {
      const hasForm = tab?.form !== null;
      console.log(hasForm)
      const filteredNestedTabs = hasForm ? [] : filterTabsWithForms(tab?.tabs);
      console.log(filteredNestedTabs)
      return [
        hasForm ? tab : null,
        ...filteredNestedTabs,
      ];
    }).filter(tab => tab !== null);
  }

  useEffect(() => {
    setFilteredTabs(filterTabsWithForms(candidateFormWorkflow.tabs))
  }, [candidateFormWorkflow])
  // const filteredTabs = filterTabsWithForms(candidateFormWorkflow.tabs);

  const [FinalFilteredTabs, setFinalFilteredTabs] = useState([]);
  console.log(FinalFilteredTabs)

  useEffect(() => {
    const accessibleStages = filteredTabs?.filter((stage) => {
      if (!stage.form) return false;
      const formName = stage?.form?.form_name;
      return privileges.includes(formName);
    });
    setFinalFilteredTabs(accessibleStages);
  }, [filteredTabs]);

  const updateSequenceWithForms = (filteredTabs) => {
    const formCount = filteredTabs?.length;
    const newSequence = [];
    for (let i = 0; i < formCount; i++) {
      newSequence.push([i]);
    }
    return newSequence;
  }



  const getCurrentFormName = (seq, tabs) => {
    if (seq.length === 1) {
      return tabs[seq[0]]?.form;
    }
    const nextTabs = tabs[seq[0]]?.tabs;
    seq.shift();
    return getCurrentFormName(seq, nextTabs);
  };

  // Handle Next button
  // Goto next tab only if next tab exists
  const nextLevel = () => {
    console.log("next level:", level);
    if (sequenceList.length - 1 > level) {
      setLevel((level) => (level += 1));
    }
  };

  // Handle Prev button
  // Goto previous tab only if previous tab exists
  const prevLevel = () => {
    console.log("prev level:", level);
    if (level > 0) {
      setLevel((level) => (level -= 1));
    }
  };

  useEffect(() => {
    if (sequenceList.length !== 0) {
      setCurrentSequence(sequenceList[level]);
    }
  }, [level, sequenceList]);

  useEffect(() => {
    if (currentSequence.length !== 0 && steppers.length !== 0) {
      setCurrentForm(
        getCurrentFormName([...currentSequence], [...steppers.tabs])
      );
    }
  }, [currentSequence, steppers]);

  // Set sequence state in current state of Sequence Context
  useEffect(() => {
    if (candidateFormWorkflow?.sequence && candidateFormWorkflow.sequence.length !== 0) {
      setSteppers(candidateFormWorkflow);
      setSequenceList(updateSequenceWithForms(FinalFilteredTabs));
    }
  }, [candidateFormWorkflow, FinalFilteredTabs]);

  const value = {
    level,
    setLevel,
    sequenceList,
    setSequenceList,
    currentSequence,
    nextLevel,
    prevLevel,
    currentForm,
    submitLoading,
    setSubmitLoading,
  };

  return (
    <SequenceContext.Provider value={value}>
      {children}
    </SequenceContext.Provider>
  );
}

export default SequenceContextProvider;
