import React from 'react'

const FluidScapes = () => {
  return (
    <div className='main-content'>
      <div class="header star_wrapper" style={{ "height": "250px" }}>
        <div id="stars"></div>
        <div id="stars2"></div>
        <div id="stars3"></div>
        <h1 id="title">Fluidscapes Careers</h1>
      </div>
    </div>
  )
}

export default FluidScapes