import axios from "axios";
import React, { useState, useContext } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";

export const CandidateEntriesContext = createContext(null);

function CandidateEntriesProvider({ children }) {
  const [candidateOtherDetails, setCandidateOtherDetails] = useState([])
  console.log(candidateOtherDetails)
  const [isDetailsLoading, setIsDetailsLoading] = useState(false)
  const [isDetailsData, setIsDetailsData] = useState(false)

  const getCandidateOtherData = async (cand_id, from) => {
    setIsDetailsLoading(true);
    let url;
    if (from === 'inprocess') {
      url = VARIABLES.url + `/all-candidate-details-career/?candidate_job_id=${cand_id}`;
    } else {
      url = VARIABLES.url + `/all-candidate-details-career/?candidate_to_be_process_id=${cand_id}`;
    }
    console.log(url);

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: url,
      //   headers: {
      //     "Authorization": `Bearer ${access}`
      //   }
    };

    try {
      const res = await axios(config);
      let response = res.data;
      console.log(response)
      setIsDetailsLoading(false);
      if (response) {
        setIsDetailsData(false);
        setCandidateOtherDetails(res.data);
      } else {
        setIsDetailsData(true);
        setCandidateOtherDetails(res.data);
      }
    } catch (error) {
      console.log(error);
      setIsDetailsLoading(false);
      // if (error?.response?.status === 403) {
      //   RefreshToken()
      //   setIsDetailsLoading(false);
      // } else {
      //   setIsCandidateData(true);
      // }
    }
  };

  return (
    <div>
      <CandidateEntriesContext.Provider
        value={{

          getCandidateOtherData,
          candidateOtherDetails,
          isDetailsLoading,
          isDetailsData
        }}
      >
        {children}
      </CandidateEntriesContext.Provider>
    </div>
  );
}

export default CandidateEntriesProvider;
