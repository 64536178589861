import React, { useContext, useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { NotificationsContext } from "../../Context/NotificationsContextProvider";
import { DashboardContext } from "../../Context/DashboardContext";

const WelcomeMessage = () => {
  const first_name = secureLocalStorage.getItem("firstName");
  const last_name = secureLocalStorage.getItem("lastName");
  const privileges = secureLocalStorage.getItem("privileges");
  const userID = secureLocalStorage.getItem("userID");

  console.log("first_name: ", first_name);
  console.log("last_name: ", last_name);

  const navigate = useNavigate();

  // const {
  //   notifications,
  //   unreadNotificationsCount,
  //   handleNotificationStatus,
  //   updateNotificationStatus,
  //   setUnreadNotificationsCount,
  //   retryCount,
  //   getNotifications,
  //   getAllNotifications,
  // } = useContext(NotificationsContext);

  const { rawPendingOnYou, getPendingOnYouData, isPendingOnYouLoading } =
    useContext(DashboardContext);

  const handleNavigation = () => {
    console.log("first");
    navigate("/pendingonyou");
  };

  // useEffect(() => {
  //   setUnreadNotificationsCount(notifications.length);
  // }, [notifications]);

  // useEffect(() => {
  //   console.log(retryCount);
  //   if (retryCount !== 0) {
  //     // enqueueSnackbar("No connection!", {
  //     //   anchorOrigin: {
  //     //     vertical: "bottom",
  //     //     horizontal: "center",
  //     //   },
  //     //   variant: "error",
  //     //   persist: true,
  //     //   preventDuplicate: true,
  //     // });
  //   } else {
  //     // closeSnackbar();
  //   }
  // }, [retryCount]);

  useEffect(() => {
    // getNotifications();
    // getAllNotifications();
    getPendingOnYouData(userID);
  }, []);

  return (
    <>
      <div className="nk-block-between ps-5 pe-5">
        <div className="nk-block-head-content w-100 d-flex align-items-center justify-content-between">
          <div className="group">
            <h3 className="nk-block-title page-title">
              {`Hello, ${first_name
                ? first_name[0].toUpperCase() + first_name.slice(1)
                : ""
                } ${last_name ? last_name[0].toUpperCase() + last_name.slice(1) : ""
                }!`}
            </h3>
            <p className="py-0">Check your daily tasks & Schedules</p>
          </div>

          <div className="d-flex">
            {/* {privileges.includes("pending_on_you") ? ( */}
            <div class="drodown me-3">
              <a
                href="#"
                class="dropdown-toggle btn btn-primary"
                data-bs-toggle="dropdown"
              >
                <span>Pending On You</span>
                <em class="dd-indc icon ni ni-chevron-right"></em>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <ul
                  class="link-list-opt no-bdr"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  {!isPendingOnYouLoading ? (
                    rawPendingOnYou?.length !== 0 ? (
                      rawPendingOnYou?.map((api) => {
                        return (
                          <li className="d-flex align-items-center pe-3">
                            <a className="dropdown-item" href="#">
                              {api?.statistics_type}
                            </a>
                            <span className="badge bg-outline-gray">
                              {api?.message}
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      <>
                        <li className="d-flex align-items-center pe-3">
                          <span className="dropdown-item">No Data</span>
                        </li>
                      </>
                    )
                  ) : (
                    <>
                      <li className="d-flex align-items-center pe-3">
                        <span className="dropdown-item">Loading...</span>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            {/* ) : (
                ""
              )} */}

          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomeMessage;
