import React from 'react'

const Footer = () => {
    return (
        <div className="nk-footer bg-white">
            <div className="container-fluid">
                <div className="nk-footer-wrap">
                    <div className="nk-footer-wrap">
                        <div className="nk-footer-copyright"> &copy; 2024
                            <a href="https://www.fluidscapes.in/"
                                target="_blank"
                                style={{ color: "#00A0E3" }}>
                                &nbsp;Fluidscapes Consultant Pvt.Ltd</a>
                        </div>
                    </div>
                    <div className="nk-footer-links">
                        <ul className="nav nav-sm">
                            <li className="nav-item dropup">
                                <a href="https://www.fluidscapes.in/who-we-are/"
                                    className="nav-link"
                                    id='button-button-aboutus'
                                    target="_blank"
                                    style={{ color: "#00A0E3" }}>
                                    <span>About Us</span></a>
                            </li>

                            <li className="nav-item">
                                <a href="https://www.fluidscapes.in/blogs/"
                                    className="nav-link"
                                    id='button-button-blog'
                                    target="_blank"
                                    style={{ color: "#00A0E3" }}>
                                    <span className="ms-1">Blog</span></a>
                            </li>
                            <li className="nav-item dropup">
                                <a href="https://www.fluidscapes.in/privacy-policy/"
                                    className="nav-link"
                                    id='button-button-privacypolicy'
                                    style={{ color: "#00A0E3" }}
                                    target="_blank">
                                    <span>Privacy Policy</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
  )
}

export default Footer