import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import EducationalInformation from "./EducationalInformation";
import PersonalInformation from "./PersonalInformation";
import ProfessionalInformation from "./ProfessionalInformation";
import AdditionalInformation from "./AdditionalInformation";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { VARIABLES } from "../../../Constant";
import { useEffect } from "react";
import { useContext } from "react";
import { UserDetailsContext } from "../../../Context/UserDetailsContext";
import { GenericDropdownContext } from "../../../Context/GenericDropdownContext";
import { TypeMasterContext } from "../../../Context/TypeMaster";
import { RefreshTokenContext } from "../../../Context/RefreshTokenContext";
import { TypemasterDropdownContext } from "../../../Context/TypemasterDropdownContext";

const ApplyJobOpen = () => {
  const { isAccess, RefreshToken } = useContext(RefreshTokenContext);
  const { getRoleType, getGender } = useContext(TypeMasterContext)
  const { getDropdownData } = useContext(TypemasterDropdownContext)
  const { getGenericDropdownData, getGenericParamsDropdownData } = useContext(GenericDropdownContext)

  const job_data = secureLocalStorage.getItem("job_opening_data");
  const location = useLocation();
  console.log(location?.state?.jobdata);

  const userID = secureLocalStorage.getItem("userID");

  const [activeTab, setActiveTab] = useState(() =>
    secureLocalStorage.getItem("active_tab")
  ); // Default to the first tab

  console.log(activeTab);

  // const [candidateDetails, setCandidateDetails] = useState(null)
  // console.log(candidateDetails)

  const { candidateData,
    getProfessionalDetails,
    setCandidateData } = useContext(UserDetailsContext)

  // const JobID = location?.state && location?.state?.jobdata ? location?.state?.jobdata?.id : "";
  const JobID = location?.state?.jobdata
    ? location?.state?.jobdata?.id
    : job_data?.id;

  const handleTabChange = (tabIndex) => {
    secureLocalStorage.setItem("active_tab", tabIndex);
    setActiveTab(tabIndex);
  };

  const handleNext = () => {
    const nextTab = activeTab < 4 ? activeTab + 1 : 1;
    handleTabChange(nextTab);
    getProfessionalDetails()
  };

  const handlePrev = () => {
    const prevTab = activeTab > 1 ? activeTab - 1 : 3;
    handleTabChange(prevTab);
    getProfessionalDetails()
  };

  useEffect(() => {
    if (isAccess) {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
      getDropdownData(access);
    } else {
      let access = secureLocalStorage.getItem("access");
      console.log(access);
      getGenericDropdownData(access);
      getGenericParamsDropdownData(access);
      getDropdownData(access);
    }
  }, [isAccess]);

  useEffect(() => {
    getRoleType();
    getGender();
  }, []);


  return (
    <>
      <Helmet>
        <title>
          {location?.state?.jobdata
            ? location?.state?.jobdata?.seo_title
            : job_data?.seo_title}
        </title>
        <meta
          name="description"
          content={
            location?.state?.jobdata
              ? location?.state?.jobdata?.meta_key
              : job_data?.meta_key
          }
        />
        <meta
          name="Keywords"
          content={
            location?.state?.jobdata
              ? location?.state?.jobdata?.keywords
              : job_data?.keywords
          }
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div class="main-content strip-bg text-white pt-3 pb-4 bg-dark">
        <div class="container pb-2 pt-2">
          <div class="text-center">
            <h1 class="font-large-5 font-weight-normal m-0 text-white">
              {location?.state?.jobdata
                ? location?.state?.jobdata?.job_title
                : job_data?.job_title}
            </h1>
            <p class="m-0 text-uppercase font-small white-light align-items-center text-center">
              <span>
                {location?.state?.jobdata
                  ? location?.state?.jobdata?.department
                    ?.department_display_name
                  : job_data?.department_name}
              </span>
              &nbsp;
              <i class="fas fa-map-marker-alt mr-2"></i>
              <span>
                (
                {location?.state?.jobdata
                  ? location?.state?.jobdata?.location
                  : job_data?.location}
                )
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="card">
            <div className="card-inner">
              <ul className="nav nav-tabs">
                <li className={`nav-item ${activeTab === 1 ? "active" : ""}`}>
                  <a className="nav-link">Personal Information</a>
                </li>
                <li className={`nav-item ${activeTab === 2 ? "active" : ""}`}>
                  <a className="nav-link">Professional Information</a>
                </li>
                <li className={`nav-item ${activeTab === 3 ? "active" : ""}`}>
                  <a className="nav-link">Educational Information</a>
                </li>
                {/* <li className={`nav-item ${activeTab === 4 ? "active" : ""}`}>
                  <a className="nav-link">Additional Information</a>
                </li> */}
              </ul>
              <div className="tab-content">
                <div className={`tab-pane ${activeTab === 1 ? "active" : ""}`}>
                  <PersonalInformation handleNext={handleNext} />
                </div>
                <div className={`tab-pane ${activeTab === 2 ? "active" : ""}`}>
                  <ProfessionalInformation
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                    candidateJobData={location?.state?.candidateJobData}
                  />
                </div>
                <div className={`tab-pane ${activeTab === 3 ? "active" : ""}`}>
                  <EducationalInformation
                    // handleNext={handleNext}
                    handlePrev={handlePrev}
                  />
                </div>
                {/* <div className={`tab-pane ${activeTab === 4 ? "active" : ""}`}>
                  <AdditionalInformation
                    JobID={JobID}
                    handlePrev={handlePrev}
                  />
                </div> */}
              </div>
              {/* <div className='d-flex justify-content-between'>
                {activeTab === 1 ? <div></div> : <button className='btn btn-primary' onClick={handlePrev}>Previous</button>}
                {activeTab === 3 ? <button className='ms-2 btn btn-primary' onClick={handleNext}>Submit</button> : <button className='ms-2 btn btn-primary' onClick={handleNext}>Next</button>}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplyJobOpen;
