import React from 'react'
import Header from './Header'
import Footer from './Footer'
import FluidScapes from './FluidScapes'
import Content from './Content'


const Base = (props) => {
  return (
    <>
      <div className="nk-app-root">
        {/* <!-- main @s --> */}
        <div className="nk-main ">
          {/* <!-- wrap @s --> */}
          <div className="nk-wrap p-0">
            <Header />
            <FluidScapes />
            <Content loginHandler={props.loginHandler} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Base