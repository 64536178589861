import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import { VARIABLES } from "../../../Constant";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
import { CandidateEntriesContext } from "../../../Context/CandidateEntriesContext";

const EditProfessional = (props) => {
    console.log("props: ", props)

    const { getCandidateOtherData } = useContext(CandidateEntriesContext);

    const {
        handleSubmit,
        control,
        register,
        setValue,
        formState: { errors, isDirty },
        reset,
        watch,
        getValues,
    } = useForm({
        defaultValues: {
            experiences: [
                {
                    previously_worked: "No",
                    profile_name: "",
                    organization_name: "",
                    start_date: "",
                    end_date: "",
                    // job_responsibilities: "",
                    comments: "",

                },
            ],
        },
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: "experiences",
        keyName: "key"
    });

    const userID = secureLocalStorage.getItem("userID");

    const watchPreviouslyWorked = watch("previously_worked");

    const location = useLocation();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);

    const editedJobPost = props?.candidateData

    const handleModalCloseAction = () => {
        const closeButton = document.getElementById("button-button-closeprofessionaldetails");
        if (closeButton) {
            closeButton.click();
            console.log("clicked")
        } else {
            console.log("Close button not found");
        }
        reset();
    };

    const handleModalClose = () => {
        reset();
    };

    const onSubmit = (data) => {
        console.log(data)
        if (props.candidateData.professional_details.length > 0) {
            var professional = JSON.stringify({
                professional_details: data.experiences.map((pro, index) => {
                    return {
                        profile_name: pro.profile_name,
                        organization_name: pro.organization_name,
                        start_date: pro.start_date || null,
                        end_date: pro.end_date || null,
                        comments: pro.comments,
                        candidate_job_id: props?.editpost?.id,
                        previously_worked: data.previously_worked.value,
                        id: props.candidateData.professional_details[index]?.id || null,
                    };
                }),
                created_by_id: userID,
                updated_by_id: userID,
                details_to_be_removed: detailToBeRemoved
            });

            const url = VARIABLES.url + `/api/professionaldetails/`;

            let config = {
                method: "patch",
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    "Content-Type": "application/json",
                },
                data: professional,
            };

            setIsLoading(true);
            axios.request(config)
                .then((response) => {
                    AdditionalDataSubmit(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Failed to update professional details"
                    })
                });
        } else {
            var professional = JSON.stringify({
                professional_details: data.experiences.map((pro) => {
                    return {
                        profile_name: pro.profile_name,
                        organization_name: pro.organization_name,
                        start_date: pro.start_date || null,
                        end_date: pro.end_date || null,
                        comments: pro.comments,
                        candidate_job_id: props?.editpost?.id,
                        previously_worked: data.previously_worked.value,
                    };
                }),
                created_by_id: userID,
                updated_by_id: userID,
                details_to_be_removed: detailToBeRemoved
            });

            const url = VARIABLES.url + "/api/professionaldetails/";

            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: url,
                headers: {
                    "Content-Type": "application/json",
                },
                data: professional,
            };

            setIsLoading(true);
            axios.request(config)
                .then((response) => {
                    AdditionalDataSubmit(data);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: "Failed to update professional details"
                    })
                });
        }
    };

    const AdditionalDataSubmit = (data) => {
        var candidate_info = JSON.stringify({
            job_opening: props.JobID,
            user_id: userID,
            notice_period: data?.notice_period,
            current_previous_company: data.experiences?.[0]?.organization_name,
            current_salary: data?.current_ctc,
            expected_salary: data?.expected_ctc,
            experience: data?.experience,
            relevant_experience: data.relevant_experience,
            updated_by_id: userID,
        });

        const url = VARIABLES.url + "/api/candidatejob/" + props?.editpost?.id + "/?send_from=career";
        console.log(url);
        let config = {
            method: "patch",
            url: url,
            headers: {
                "Content-Type": "application/json",
            },
            data: candidate_info,
        };

        axios
            .request(config)
            .then((response) => {
                setIsLoading(false);
                getCandidateOtherData(location?.state?.editpost?.id, location.state.from)
                Swal.fire({
                    icon: "success",
                    title: "You have succesfully updated your Professional details"
                }).then(() => {
                    handleModalCloseAction()
                });
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Failed to update professional details"
                })
            });
    };

    const [detailToBeRemoved, setDetailToBeRemoved] = useState([])

    const removeDetails = (index, detail_id) => {
        if (detail_id) {
            Swal.fire({
                text: "Are you sure? You will not be able to recover this data!",
                showDenyButton: true,
                confirmButtonText: "Yes",
                denyButtonText: `No, cancel!`,
            }).then((result) => {
                if (result.isConfirmed) {
                    setDetailToBeRemoved([...detailToBeRemoved, detail_id])
                    remove(index)
                }
            });
        } else {
            remove(index)
        }

    }

    useEffect(() => {
        const storedData = props?.candidateData?.professional_details;
        console.log(storedData);
        if (storedData?.length > 0) {
            let values = {};
            values.experiences = storedData;
            values.previously_worked = { value: storedData?.[0]?.previously_worked, label: storedData?.[0]?.previously_worked }
            values.notice_period = props.candidateData.other_details?.[0]?.notice_period;
            values.current_previous_company = props.candidateData.other_details?.[0]?.current_previous_company;
            values.current_ctc = props.candidateData.other_details?.[0]?.current_salary;
            values.expected_ctc = props.candidateData.other_details?.[0]?.expected_salary;
            values.experience = props.candidateData.other_details?.[0]?.experience;
            values.relevant_experience = props.candidateData.other_details?.[0]?.relevant_experience;
            reset(values);
        } else if (storedData?.length === 0) {
            let values = {};
            values.experiences = [
                {
                    profile_name: "",
                    organization_name: "",
                    start_date: "",
                    end_date: "",
                    comments: "",
                },
            ]
            reset(values);
        }
    }, [props.candidateData]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setSubmitMessage(null);
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [submitMessage]);

    return (
        <>
            <div
                className="modal fade"
                id="professional"
                aria-hidden="true"
                aria-labelledby="exampleModalToggleLabel2"
                tabindex="-1"
            >
                <div className="modal-dialog">
                    <div className="modal-content w-100">
                        <div className="modal-header">
                            <h4 class="modal-title">Edit professional details</h4>
                            <button
                                type="button"
                                className="btn-close"
                                id="button-button-closeprofessionaldetails"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleModalClose}
                            ></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="row gs-4">
                                            <div className="col-lg-6">
                                                <div className="form-group">
                                                    <label className="form-label">
                                                        Previously Worked ?{" "}
                                                        <span className="fs-8 text-danger">*</span>
                                                    </label>
                                                    <Controller
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={[
                                                                    { value: "Yes", label: "Yes" },
                                                                    { value: "No", label: "No" },
                                                                ]}
                                                                isClearable={true}
                                                                id="select-forms-previously_worked"
                                                            />
                                                        )}
                                                        defaultValue=""
                                                        rules={{ required: true }}
                                                        name={`previously_worked`}
                                                        control={control}

                                                    />
                                                    {errors.previously_worked && (
                                                        <span className="fs-8 text-danger">
                                                            Previously Worked is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {watchPreviouslyWorked?.value === "Yes" && (
                                            <>
                                                <div className="row gs-4">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Total Work Experience
                                                                <span className="fs-8 text-danger"> *</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("experience", { required: true })}
                                                                    defaultValue=""
                                                                    className="form-control"
                                                                    id="number-forms-experience"
                                                                    type="number"
                                                                    placeholder="Total years of experience"
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                                <span class="input-group-text" id="basic-addon2">
                                                                    years
                                                                </span>
                                                            </div>
                                                            {errors.experience &&
                                                                errors.experience.type === "required" && (
                                                                    <span className="fs-8 text-danger">
                                                                        Experience is required
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Total Relevant Experience
                                                                <span className="fs-8 text-danger"> *</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("relevant_experience", { required: true })}
                                                                    defaultValue=""
                                                                    className="form-control"
                                                                    id="number-forms-relevant_experience"
                                                                    type="number"
                                                                    placeholder="Total Relevant Experience"
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                                <span class="input-group-text" id="basic-addon2">
                                                                    years
                                                                </span>
                                                            </div>
                                                            {errors.relevant_experience &&
                                                                errors.relevant_experience.type === "required" && (
                                                                    <span className="fs-8 text-danger">
                                                                        Total Relevant Experience is required
                                                                    </span>
                                                                )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Expected CTC{" "}
                                                                <span className="fs-8 text-danger">*</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("expected_ctc", { required: true })}
                                                                    defaultValue=""
                                                                    className="form-control"
                                                                    id="number-forms-expected_ctc"
                                                                    type="number"
                                                                    placeholder="Expected CTC in lakhs per annum "
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                                <span class="input-group-text" id="basic-addon2">
                                                                    lakhs per annum
                                                                </span>
                                                            </div>
                                                            {errors.expected_ctc && (
                                                                <span className="fs-8 text-danger">
                                                                    Expected CTC is required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row gs-4 pb-3">
                                                    <div className="col-lg-12 col-md-12">
                                                        <h4 className="title">Current/Previous Company Details</h4>
                                                    </div>
                                                </div>
                                                <div className="row gs-4">
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Notice Period
                                                                <span className="fs-8 text-danger">*</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("notice_period", { required: true })}
                                                                    defaultValue=""
                                                                    className="form-control"
                                                                    id="number-forms-notice_period"
                                                                    type="number"
                                                                    placeholder="Enter notice period in days"
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                                <span class="input-group-text" id="basic-addon2">
                                                                    days
                                                                </span>

                                                            </div>
                                                            {errors.notice_period && (
                                                                <span className="fs-8 text-danger">
                                                                    Notice Period is required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <div className="form-group">
                                                            <label className="form-label">
                                                                Last Drawn CTC
                                                                <span className="fs-8 text-danger">*</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <input
                                                                    {...register("current_ctc", { required: true })}
                                                                    defaultValue=""
                                                                    className="form-control"
                                                                    id="number-forms-current_ctc"
                                                                    type="number"
                                                                    placeholder="Current CTC in lakhs per annum"
                                                                    onWheel={(e) => e.target.blur()}
                                                                />
                                                                <span class="input-group-text" id="basic-addon2">
                                                                    lakhs per annum
                                                                </span>
                                                            </div>
                                                            {errors.current_ctc && (
                                                                <span className="fs-8 text-danger">
                                                                    Current CTC is required
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                </div>
                                            </>
                                        )}
                                        {fields.map((item, index) => (
                                            <div key={item}>
                                                {watchPreviouslyWorked?.value === "Yes" && (
                                                    <>
                                                        <div className="row gy-4 border-bottom border-0">
                                                            <div className="form-group" style={{ display: "none" }}>
                                                                <input
                                                                    {...register(`experiences.${index}.id`)}
                                                                    className="form-control"
                                                                    type="text" hidden
                                                                />
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="form-group">
                                                                    <label className="form-label">
                                                                        Organization Name{" "}
                                                                        <span className="fs-8 text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        {...register(
                                                                            `experiences.${index}.organization_name`,
                                                                            {
                                                                                required: true,
                                                                            }
                                                                        )}
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder="Enter Organization Name"
                                                                    />
                                                                    {errors.experiences?.[index]?.organization_name && (
                                                                        <span className="fs-8 text-danger">
                                                                            Organization Name is required
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="form-group">
                                                                    <label className="form-label">
                                                                        Designation
                                                                        <span className="fs-8 text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        {...register(`experiences.${index}.profile_name`, {
                                                                            required: true,
                                                                        })}
                                                                        className="form-control"
                                                                        type="text"
                                                                        placeholder="Enter Designation"
                                                                    />
                                                                    {errors.experiences?.[index]?.profile_name && (
                                                                        <span className="fs-8 text-danger">
                                                                            Designation is required
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">
                                                                        Date of Joining
                                                                        <span className="fs-8 text-danger">*</span>
                                                                    </label>
                                                                    <input
                                                                        {...register(`experiences.${index}.start_date`, {
                                                                            required: true,
                                                                        })}
                                                                        defaultValue=""
                                                                        className="form-control"
                                                                        id="date-forms-start_date"
                                                                        type="date"
                                                                        placeholder="Enter Date of joining"
                                                                    />
                                                                    {errors.experiences?.[index]?.start_date && (
                                                                        <span className="fs-8 text-danger">
                                                                            Date of joining is required
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">
                                                                        Last Working Day
                                                                    </label>
                                                                    <input
                                                                        {...register(`experiences.${index}.end_date`, {
                                                                            validate: (value) => {
                                                                                if (!value) return true;
                                                                                const startDate = new Date(
                                                                                    getValues(`experiences.${index}.start_date`)
                                                                                );
                                                                                const endDate = new Date(value);
                                                                                return (
                                                                                    endDate >= startDate ||
                                                                                    "End date should not be before start date"
                                                                                );
                                                                            },
                                                                        })}
                                                                        defaultValue=""
                                                                        className="form-control"
                                                                        id="date-forms-end_date"
                                                                        type="date"
                                                                        placeholder="Enter End Date"
                                                                    />
                                                                    {errors.experiences?.[index]?.end_date && (
                                                                        <span className="fs-8 text-danger">
                                                                            {errors.experiences[index].end_date.message}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Comments</label>
                                                                    <textarea
                                                                        {...register(`experiences.${index}.comments`)}
                                                                        className="form-control"
                                                                        rows="4"
                                                                        placeholder="Enter Comments"
                                                                    />
                                                                </div>
                                                            </div>

                                                            {index > 0 && (
                                                                <div className="col-lg-4 align-self-center">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-danger"
                                                                        onClick={() => removeDetails(index, item.id)}
                                                                    >
                                                                        <em class="icon ni ni-trash-alt"></em>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}

                                        {watchPreviouslyWorked?.value === "Yes" && (<div className="col-12 d-flex align-center">
                                            <h4 className="title mt-3">To add Professional Details click on "Add"</h4>
                                            <button
                                                type="button"
                                                className="btn btn-primary mt-4"
                                                onClick={() =>
                                                    append({
                                                        profile_name: "",
                                                        organization_name: "",
                                                        start_date: "",
                                                        end_date: "",
                                                        comments: "",
                                                    })
                                                }
                                            >
                                                Add
                                            </button>
                                        </div>)}

                                        <div className="form-group">
                                            <button
                                                type="button"
                                                className="btn btn-lg btn-light me-3"
                                                id="button-button-closeprofessional"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                                onClick={handleModalClose}>
                                                Cancel
                                            </button>
                                            {isLoading ? (
                                                <button
                                                    id="button-loading"
                                                    className="btn text-dark"
                                                    disabled >
                                                    Loading...
                                                </button>
                                            ) : (
                                                <button
                                                    type="submit"
                                                    className="btn btn-lg btn-primary"
                                                    id="button-button-submit"
                                                >
                                                    Submit
                                                </button>
                                            )}
                                        </div>

                                        {submitMessage && (
                                            <div
                                                className={`alert alert-${submitMessage.type} mt-4`}
                                                role="alert"
                                            >
                                                <i className={`bi bi-${submitMessage.icon} me-2`}></i>
                                                {submitMessage.message}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    {submitMessage && (
                        <div
                            className="example-alerts position-fixed bottom-0 end-0 p-3"
                            style={{ zIndex: 100 }}
                        >
                            <div className="gy-4">
                                <div className="example-alert">
                                    <div
                                        className={`alert alert-fill alert-${submitMessage.type} alert-icon`}
                                    >
                                        <em
                                            className={`icon ni ni-${submitMessage.icon}-circle`}
                                        ></em>{" "}
                                        <strong>{submitMessage.message}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EditProfessional;