import axios from "axios";
import React, { useContext, useState } from "react";
import { createContext } from "react";
import secureLocalStorage from "react-secure-storage";
import { VARIABLES } from "../Constant";
import { RefreshTokenContext } from "./RefreshTokenContext";

export const WorkflowContext = createContext(null);

function WorkflowProvider({ children }) {
  const { RefreshToken } = useContext(RefreshTokenContext);
  // To store Workflow Data.
  const [isWorkflowData, setIsWorkflowData] = useState(false);
  const [rawWorkflowData, setRawWorkflowData] = useState([]);
  const [workflowEntries, setWorkflowEntries] = useState(5);
  const [workflowCount, setWorkflowCount] = useState(null);
  const [workflowPageCount, setWorkflowPageCount] = useState(0);
  const [isWorkflowLoading, setIsWorkflowLoading] = useState(true);

  // formworkflow data as per workflow id stored in "rawUserDetails"
  const [isFormWorkflowData, setIsFormWorkflowData] = useState(false);
  const [rawFormWorkflowData, setRawFormWorkflowData] = useState([]);
  const [formWorkflowEntries, setFormWorkflowEntries] = useState(5);
  const [formWorkflowCount, setFormWorkflowCount] = useState(null);
  const [formWorkflowPageCount, setFormWorkflowPageCount] = useState(0);
  const [isFormWorkflowLoading, setIsFormWorkflowLoading] = useState(true);

  // To store the formWorkFlowOptions Data
  const [formWorkflowOptions, setFormWorkflowOptions] = useState([]);

  // To store session of workflow tab
  const [workflowSession, setWorkflowSession] = useState([]);

  // To store the candidate workflow in ""
  const [candidateFormWorkflow, setCandidateFormWorkflow] = useState({});
  const [isCandidateFormWorkflowLoading, setIsCandidateFormWorkflowLoading] =
    useState(false);
  const [dependsOnDropdownData, setDependsOnDropdownData] = useState([]);

  // "getWorkflowData" API call to get workflow data
  const getWorkflowData = async (
    page,
    searchWorkflow,
    category_name,
    access
  ) => {
    setIsWorkflowLoading(true);
    console.log(searchWorkflow);
    let url;
    if (searchWorkflow) {
      url =
        VARIABLES.url +
        `/api/workflow/?entries=${workflowEntries}&page=${page}&workflow_category=${category_name}&event=${searchWorkflow}`;
    } else {
      url =
        VARIABLES.url +
        `/api/workflow/?entries=${workflowEntries}&page=${page}&workflow_category=${category_name}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsWorkflowLoading(false);
      if (response.length !== 0) {
        setIsWorkflowData(false);
        setRawWorkflowData(res.data.data);
        console.log(res.data.data);
      } else {
        setIsWorkflowData(true);
        setRawWorkflowData(res.data.data);
      }
      const total = res.data.count;
      setWorkflowCount(total);
      setWorkflowPageCount(Math.ceil(total / workflowEntries));
    } catch (error) {
      console.log(error);
      setIsWorkflowLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsWorkflowData(false);
      } else {
        setIsWorkflowData(true);
      }
    }
  };

  const handleWorkflowClick = async (data, searchWorkflow, category_name) => {
    let access = secureLocalStorage.getItem("access");
    console.log(access);
    console.log(data.selected);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getWorkflowData(
      currentPage,
      searchWorkflow,
      category_name,
      access
    );
  };

  //  to get the formworkflow api call with specific workflow id
  const getFormWorkflowData = async (page, wk_id, searchedData, access) => {
    setIsFormWorkflowLoading(true);
    let url;
    if (searchedData) {
      url =
        VARIABLES.url +
        `/api/formworkflow/?entries=${formWorkflowEntries}&page=${page}&workflow_id=${wk_id}&event=${searchedData}`;
    } else {
      url =
        VARIABLES.url +
        `/api/formworkflow/?entries=${formWorkflowEntries}&page=${page}&workflow_id=${wk_id}`;
    }
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
      headers: {
        Authorization: `Bearer ${access}`,
      },
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsFormWorkflowLoading(false);
      if (response.length !== 0) {
        setIsFormWorkflowData(false);
        setRawFormWorkflowData(res.data.data);
      } else {
        setIsFormWorkflowData(true);
        setRawFormWorkflowData(res.data.data);
      }
      const total = res.data.count;
      setFormWorkflowCount(total);
      setFormWorkflowPageCount(Math.ceil(total / formWorkflowEntries));
    } catch (error) {
      console.log(error);
      setIsFormWorkflowLoading(false);
      if (error?.response?.status === 403) {
        RefreshToken();
        setIsFormWorkflowData(false);
      } else {
        setIsFormWorkflowData(true);
      }
    }
  };

  const handleFormWorkflowClick = async (data, wk_id, searchedData) => {
    let access = secureLocalStorage.getItem("access");
    console.log(access);
    console.log(data.selected);
    let currentPage = data.selected + 1;
    const commentsFormServer = await getFormWorkflowData(
      currentPage,
      wk_id,
      searchedData,
      access
    );
  };

  // "getFormWorkflowDropdownData" API call to get formworkflow data as dropdown
  const getFormWorkflowDropdownData = async (wk_id) => {
    const url = VARIABLES.url + `/api/formworkflow/?workflow_id=${wk_id}`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setFormWorkflowOptions(
        res.data.data.map((pro) => ({
          value: pro.id,
          label: pro.tab_name,
          sequence: pro.sequence,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  // "getWorkflowSession" API call to get the session data store in "workflowSession"
  const getWorkflowSession = async (cand_id) => {
    try {
      const res = await axios.get(
        VARIABLES.url + `/api/workflowsession/?candidate_job_id=${cand_id}&send_from=career`
      );
      setWorkflowSession(res.data.data);
      console.log(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // "getCandidateStepperFlow" AP call to get the candidateformWorkflow
  const getCandidateStepperFlow = async (cand_id) => {
    const url = VARIABLES.url + `/api/careercandidatestepper/${cand_id}/?send_from=career`;
    console.log(url);
    setIsCandidateFormWorkflowLoading(true);
    try {
      const res = await axios.get(url);
      setCandidateFormWorkflow(res.data);
      setIsCandidateFormWorkflowLoading(false);
      console.log(res.data);
    } catch (error) {
      console.log(error);
      setIsCandidateFormWorkflowLoading(false);
    }
  };

  const getDependsOnDropdownData = async (cand_id) => {
    const url =
      VARIABLES.url + `/api/candidateformworkflow/?candidate_job=${cand_id}&send_from=career`;
    console.log(url);
    try {
      const res = await axios.get(url);
      setDependsOnDropdownData(
        res.data.data.map((pro) => ({
          value: pro.id,
          label: pro.tab_name,
        }))
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <WorkflowContext.Provider
        value={{
          rawWorkflowData,
          getWorkflowData,
          workflowEntries,
          workflowPageCount,
          setWorkflowEntries,
          workflowCount,
          handleWorkflowClick,
          isWorkflowData,
          isWorkflowLoading,

          formWorkflowOptions,
          workflowSession,

          candidateFormWorkflow,
          isCandidateFormWorkflowLoading,
          dependsOnDropdownData,

          rawFormWorkflowData,
          getFormWorkflowData,
          formWorkflowEntries,
          formWorkflowPageCount,
          setFormWorkflowEntries,
          formWorkflowCount,
          handleFormWorkflowClick,
          isFormWorkflowData,
          isFormWorkflowLoading,

          getFormWorkflowDropdownData,
          getWorkflowSession,
          getCandidateStepperFlow,
          getDependsOnDropdownData,
        }}
      >
        {children}
      </WorkflowContext.Provider>
    </div>
  );
}

export default WorkflowProvider;
