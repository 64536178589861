import React, { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../../Context/DashboardContext";
import { NavLink, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";

const InProcessApplication = () => {
    const userID = secureLocalStorage.getItem("userID");
    console.log(userID);

    const {
        inProcessData,
        inProcessDataLoading,
        inProcessEntries,
        inProcessPageCount,
        inProcessEntryCount,
        setInProcessEntries,
        handleInProcessDataPageClick,
        getInProcessData,
    } = useContext(DashboardContext);

    const handleEntries = (event) => {
        setInProcessEntries(event.target.value);
        getInProcessData(1, userID);
    };

    useEffect(() => {
        getInProcessData(1, userID);
    }, []);

    return (
        <>
            <div class="card">
                <div class="card-inner-group">
                    <div className="card-inner">
                        <h6 class="h6">Applied Applications</h6>
                    </div>
                    {inProcessDataLoading ? (
                        <div className="mt-2">
                            <div className="card-inner"> loading... </div>
                        </div>
                    ) : (
                        <div class="card-inner p-0">
                            <div className="table-responsive">
                                <table className="table">
                                    {inProcessData && inProcessData?.data?.length === 0 && (
                                        <div className="m-2">No data found</div>
                                    )}
                                    {inProcessData.data?.length > 0 && (
                                        <div class="nk-tb-list">
                                            <div class="nk-tb-item nk-tb-head">
                                                <div class="nk-tb-col tb-col-sm">
                                                    <span>Job Title</span>
                                                </div>
                                                <div class="nk-tb-col">
                                                    <span>Current/Previous Company</span>
                                                </div>
                                                <div class="nk-tb-col">
                                                    <span>Excepted Salary</span>
                                                </div>
                                                <div class="nk-tb-col">
                                                    <span>Experience</span>
                                                </div>
                                                <div class="nk-tb-col tb-col-md">
                                                    <span>Notice Period</span>
                                                </div>
                                                <div class="nk-tb-col tb-col-md">
                                                    <span>Applied on</span>
                                                </div>
                                                <div class="nk-tb-col tb-col-md">
                                                    <span>Resume</span>
                                                </div>

                                                <div class="nk-tb-col tb-col-md">
                                                    <span>Details</span>
                                                </div>
                                            </div>
                                            {inProcessData.data?.map((post, index) => (
                                                <div class="nk-tb-item" key={index}>
                                                    <div class="nk-tb-col tb-col-sm">
                                                        <span class="tb-product">
                                                            <span class="title">
                                                                {post?.job_opening?.job_title || "-"}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div class="nk-tb-col">
                                                        <span class="tb-sub">
                                                            {post?.current_previous_company || "-"}
                                                        </span>
                                                    </div>
                                                    <div class="nk-tb-col">
                                                        <span class="tb-lead">
                                                            {post?.expected_salary || "-"}
                                                        </span>
                                                    </div>
                                                    <div class="nk-tb-col">
                                                        <span class="tb-sub">{post?.experience}</span>
                                                    </div>
                                                    <div class="nk-tb-col">
                                                        <span class="tb-sub">
                                                            {post?.notice_period || "-"}
                                                        </span>
                                                    </div>
                                                    <div class="nk-tb-col">
                                                        <span class="tb-sub">{post?.applied_on ?
                                                            moment(post?.applied_on).format('MMMM Do YYYY, h:mm')
                                                            : "-"}
                                                        </span>
                                                    </div>
                                                    <div class="nk-tb-col tb-col-md">
                                                        {post?.candidate_resume?.resume ? (
                                                            <a
                                                                className="btn btn-primary"
                                                                id="button-button-viewresume"
                                                                href={post?.candidate_resume?.resume}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <em
                                                                    className="icon ni ni-file-docs"
                                                                    style={{ color: "white" }}
                                                                ></em>
                                                            </a>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </div>

                                                    <div className="nk-tb-col">
                                                        <NavLink
                                                            to={"/candidateotherdetails"}
                                                            state={{ editpost: post, from: "applied" }}
                                                            className="btn btn-sm btn-primary"
                                                            id="button-button-candidateotherdetails"
                                                        >
                                                            <em className="icon ni ni-eye"></em>
                                                        </NavLink>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </table>
                            </div>
                        </div>
                    )}
                    <div className="card-inner">
                        <div className="nk-block-between-md g-3">
                            <div className="g">
                                <ReactPaginate
                                    key={inProcessPageCount}
                                    previousLabel={"previous"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    pageCount={inProcessPageCount}
                                    marginPagesDisplayed={2}
                                    // pageRangeDisplayed={3}
                                    onPageChange={(data) =>
                                        handleInProcessDataPageClick(data, userID)
                                    }
                                    containerClassName={
                                        "pagination justify-content-center justify-content-md-start"
                                    }
                                    pageClassName={"paginate_button page-item"}
                                    pageLinkClassName={"page-link"}
                                    previousClassName={"page-item"}
                                    previousLinkClassName={"page-link"}
                                    nextClassName={"page-item"}
                                    nextLinkClassName={"page-link"}
                                    breakClassName={"paginate_button page-item"}
                                    breakLinkClassName={"page-link"}
                                    activeClassName={"active"}
                                />
                            </div>
                            <div className="g">
                                <div className="pagination-goto d-flex justify-content-center justify-content-md-start gx-3">
                                    <div>
                                        Total entries:&nbsp;
                                        <span className="fw-bold">
                                            {inProcessEntryCount === null ? 0 : inProcessEntryCount}
                                        </span>
                                    </div>
                                    <div>No of entries </div>
                                    <div>
                                        <select
                                            className="form-select js-select2"
                                            data-search="on"
                                            data-dropdown="xs center"
                                            value={inProcessEntries}
                                            onChange={handleEntries}
                                        >
                                            <option value="5">5</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InProcessApplication;