import axios from "axios";
import React, { useEffect, useState } from "react";
import { createContext } from "react";
import { VARIABLES } from "../Constant";
import secureLocalStorage from "react-secure-storage";

export const DashboardContext = createContext(null);

function DashboardProvider({ children }) {
  const [pendingData, setPendingData] = useState([]);
  const [appliedData, setAppliedData] = useState([]);
  const [applicationUnderProcess, setApplicationUnderProcess] = useState(0);
  const [pendingDataLoading, setPendingDataLoading] = useState([]);
  const [appliedDataLoading, setAppliedDataLoading] = useState([]);
  console.log(pendingData);
  console.log(appliedData);

  const [appliedEntryCount, setAppliedEntryCount] = useState(0);
  const [pendingEntryCount, setPendingEntryCount] = useState(0);
  const [pendingPageCount, setPendingPageCount] = useState(0);
  console.log(pendingPageCount);
  const [appliedPageCount, setAppliedPageCount] = useState(0);
  console.log(appliedPageCount);
  const [pendingEntries, setPendingEntries] = useState(5);
  const [appliedEntries, setAppliedEntries] = useState(5);

  const [inProcessData, setInProcessData] = useState([]);
  const [inProcessDataLoading, setInProcessDataLoading] = useState([]);
  const [inProcessEntryCount, setInProcessEntryCount] = useState(0);
  const [inProcessPageCount, setInProcessPageCount] = useState(0);
  const [inProcessEntries, setInProcessEntries] = useState(5);

  const [rawPendingOnYou, setRawPendingOnYou] = useState([]);
  const [isPendingOnYouLoading, setIsPendingOnYouLoading] = useState(false);
  const [isPendingOnYouData, setIsPendingOnYouData] = useState(false);
  const [PendingOnYouEntries, setPendingOnYouEntries] = useState(5);
  const [PendingOnYouCount, setPendingOnYouCount] = useState(null);
  const [PendingOnYouPageCount, setPendingOnYouPageCount] = useState(0);

  // const [userID, setUserID] = useState(null);
  // console.log(userID);

  const getPendingData = async (page, userID) => {
    setPendingDataLoading(true);
    const url = `/api/candidatetobeprocess/?entries=${pendingEntries}&page=${page}&is_draft=True&user=${userID}`;
    console.log(url);
    try {
      const res = await axios.get(VARIABLES.url + url);
      setPendingData(res.data);
      setPendingDataLoading(false);
      const total = res.data.count;
      setPendingEntryCount(total);
      setPendingPageCount(Math.ceil(total / pendingEntries));
    } catch (error) {
      setPendingDataLoading(false);
      console.log(error);
    }
  };

  const handlePendingDataPageClick = async (data, userID) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await getPendingData(currentPage, userID);
  };

  const getAppliedData = async (page, userID) => {
    setAppliedDataLoading(true);
    try {
      const res = await axios.get(
        VARIABLES.url +
        `/api/candidatejob/?entries=${appliedEntries}&page=${page}&is_draft=False&user=${userID}`
      );
      setAppliedData(res.data);
      setAppliedDataLoading(false);
      const total = res.data.count;
      setApplicationUnderProcess(total)
      setAppliedPageCount(Math.ceil(total / appliedEntries));
    } catch (error) {
      setAppliedDataLoading(false);
      console.log(error);
    }
  };

  const handleAppliedDataPageClick = async (data, userID) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await getAppliedData(currentPage, userID);
  };

  const getInProcessData = async (page, userID) => {
    setInProcessDataLoading(true);
    const url = `/api/candidatetobeprocess/?entries=${inProcessEntries}&page=${page}&is_draft=False&user=${userID}`;
    console.log(url);
    try {
      const res = await axios.get(VARIABLES.url + url);
      setInProcessData(res.data);
      setInProcessDataLoading(false);
      const total = res.data.count;
      setInProcessEntryCount(total);
      setAppliedEntryCount(total);
      setInProcessPageCount(Math.ceil(total / inProcessEntries));
    } catch (error) {
      setInProcessDataLoading(false);
      console.log(error);
    }
  };

  const handleInProcessDataPageClick = async (data, userID) => {
    console.log(data.selected);

    let currentPage = data.selected + 1;

    const commentsFormServer = await getInProcessData(currentPage, userID);
  };


  const getPendingOnYouData = async (userID) => {
    setIsPendingOnYouLoading(true);

    let url = VARIABLES.url + `/api/statistics/?user_id=${userID}`;
    console.log(url);

    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: url,
    };

    try {
      const res = await axios(config);
      let response = res.data.data;
      setIsPendingOnYouLoading(false);
      if (response.length !== 0) {
        setIsPendingOnYouData(false);
        setRawPendingOnYou(res.data.data);
      } else {
        setRawPendingOnYou(res.data.data);
        setIsPendingOnYouData(true);
      }
    } catch (error) {
      console.log(error);
      setIsPendingOnYouLoading(false);
    }
  };

  // const getUserID = () => {
  //   console.log("getting userid");
  //   const storedUserID = secureLocalStorage.getItem("userID");
  //   if (storedUserID !== null) {
  //     console.log("if block");
  //     setUserID(storedUserID);
  //   } else {
  //     console.log("elseblock");
  //     setUserID(storedUserID);
  //     setTimeout(getUserID, 1000);
  //   }
  // };

  return (
    <div>
      <DashboardContext.Provider
        value={{
          pendingData,
          pendingDataLoading,
          appliedDataLoading,
          appliedData,
          applicationUnderProcess,
          pendingEntries,
          pendingPageCount,
          appliedPageCount,
          pendingEntryCount,
          appliedEntryCount,
          setAppliedEntries,
          setPendingEntries,
          handlePendingDataPageClick,
          handleAppliedDataPageClick,
          getPendingData,
          getAppliedData,

          inProcessData,
          inProcessDataLoading,
          inProcessEntries,
          inProcessPageCount,
          inProcessEntryCount,
          setInProcessEntries,
          handleInProcessDataPageClick,
          getInProcessData,

          // getUserID,

          rawPendingOnYou,
          isPendingOnYouData,
          isPendingOnYouLoading,
          PendingOnYouEntries,
          PendingOnYouCount,
          PendingOnYouPageCount,
          getPendingOnYouData,
          setPendingOnYouEntries,
        }}
      >
        {children}
      </DashboardContext.Provider>
    </div>
  );
}

export default DashboardProvider;
