import React from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Content from './Content'


const DashboardBase = (props) => {
  return (
    <div>
      <Sidebar />
      <div className="nk-wrap">
        {/* <!-- main @s --> */}
        <Header logoutHandler={props.logoutHandler} />
        <div className="nk-content nk-content-fluid ">
          {/* <!-- wrap @s --> */}
          <div className="container-xl wide-xl">
            <div className="nk-content-body">
              <Content />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardBase