import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import Base from './components/CareerBase/Base/Base'
import DashboardBase from './components/DashBoardBase/Base/Base'

const App = () => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  console.log(isLoggedIn)

  // Set values in localstorage, userData and isLoggedIn after successful login     
  const loginHandler = (data) => {
    console.log(data)
    secureLocalStorage.setItem('isLoggedIn','USER_LOGGED_IN');
    secureLocalStorage.setItem('ID',data.id);
    secureLocalStorage.setItem('userID',data.user.id);
    secureLocalStorage.setItem('username',data.user.username);
    secureLocalStorage.setItem('firstName',data.user.first_name);
    secureLocalStorage.setItem('lastName',data.user.last_name);
    secureLocalStorage.setItem('email',data.user.email);
    // secureLocalStorage.setItem('gender',data.gender);
    // secureLocalStorage.setItem('currentLocations',data.current_locations);
    // const ConfigurablePrivileges = data.configurable_privileges.map((pr) => {return pr.privilege_name})
    // console.log(ConfigurablePrivileges)
    // secureLocalStorage.setItem('configurablePrivileges',ConfigurablePrivileges)

    // const configurable_privilleges_data = data.configurable_privileges.map((pr) => {return {value : pr.id, label : pr.privilege_name}})
    // secureLocalStorage.setItem('configurable_data',configurable_privilleges_data)
    // console.log(configurable_privilleges_data)

    const loginType = data.login_types.map((ty) => {return {value : ty.id ,label : ty.name}})
    console.log(loginType)
    secureLocalStorage.setItem('login_type',loginType);

    const role_list = data.role.map((rl) => {return rl.role_name})
    console.log(role_list)
    secureLocalStorage.setItem('role',role_list);

    const role = data.role.map((rl) => {return {label : rl.role_name,value : rl.id}})
    secureLocalStorage.setItem('role_data',role);
    console.log(role)

    const privileges_list = data.privilege_list
    secureLocalStorage.setItem('privileges',privileges_list);
    console.log(privileges_list)

    // const privileges_list = data.role.map((rl) => {return rl.privilege.map((pr) => {return pr.privilege_name}) })
    // console.log(privileges_list)
    // secureLocalStorage.setItem('privileges',privileges_list);
    setIsLoggedIn(true);
  };

  const logoutHandler = () => {
    secureLocalStorage.removeItem('isLoggedIn', 'USER_LOGGED_OUT')
    secureLocalStorage.removeItem('loggedId');
    secureLocalStorage.removeItem('id');
     secureLocalStorage.removeItem('userID');
     secureLocalStorage.removeItem('username');
     secureLocalStorage.setItem('firstName');
     secureLocalStorage.setItem('lastName');
     secureLocalStorage.removeItem('email');
    secureLocalStorage.removeItem('gender');
    secureLocalStorage.removeItem('currentLocations');
    secureLocalStorage.removeItem('privileges')
    secureLocalStorage.removeItem('job_opening_data')
    secureLocalStorage.clear()
    setIsLoggedIn(false);
    navigate("/")
  };

  useEffect(() => {
    const userStatus = secureLocalStorage.getItem('isLoggedIn');
    if (userStatus === 'USER_LOGGED_IN') {
      setIsLoggedIn(true);
    } else if (userStatus === 'USER_LOGGED_OUT') {
      setIsLoggedIn(false);
    }
  }, [])
  return (
    <>
      {!isLoggedIn && <Base loginHandler={loginHandler} />}
      {isLoggedIn && <DashboardBase logoutHandler={logoutHandler} />}
    </>
  )
}

export default App