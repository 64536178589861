import React from 'react'

const Message = (props) => {
    console.log(props.icon)
    return (
        <>
            <div className="card">
                <div className="card-inner" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <img
                        src={props?.icon}
                        alt="okay"
                        style={{
                            width: "10rem",
                            height: "10rem",
                            margin: "10px"
                        }}
                    />
                    <h1 style={{
                        margin: "10px"
                    }}>{props?.title}</h1>
                    <div style={{
                        margin: "10px",
                        width: "50%",
                        textAlign: "center"
                    }}>{props?.message}</div>
                </div>
            </div>
        </>
    )
}

export default Message