import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { JobOpeningContext } from "../../Context/JobOpeningContext";
import secureLocalStorage from "react-secure-storage";

const PageOpening = () => {
  const [firstColumnHeight, setFirstColumnHeight] = useState(0);
  console.log(firstColumnHeight);
  const secondColumnRef = useRef(null);
  const { rawJobPosting } = useContext(JobOpeningContext);
  console.log(rawJobPosting);

  const [onLoad, setOnLoad] = useState(null);
  console.log("onLoad: ", onLoad)
  const location = useLocation();
  const navigate = useNavigate();

  const pathname = location.pathname.slice(1);
  console.log(pathname);
  console.log(location.state);

  const getJobIdDetails = () => {
    const findSeoByJobId = rawJobPosting.find((api) => {
      return api.url_required === decodeURIComponent(pathname);
    });
    console.log(findSeoByJobId);
    setOnLoad(findSeoByJobId);
  };

  const onChangeDescription = (jobdata) => {
    navigate(`/${jobdata?.url_required}`, {
      state: { jobdata: jobdata },
    });
  };

  const onStorageHandler = (job_data) => {
    console.log(job_data);
    secureLocalStorage.setItem("job_opening_data", job_data);
  };

  useEffect(() => {
    // Measure the height of the first column and set it to the second column
    const firstColumnElement = document.querySelector(".col-xl-8");
    if (firstColumnElement) {
      setFirstColumnHeight(firstColumnElement.clientHeight);
    }
  }, [firstColumnHeight]);

  useEffect(() => {
    console.log("empty")
    if (rawJobPosting.length > 0) {
      console.log("got")
      getJobIdDetails();
    }
  }, [rawJobPosting]);

  return (
    <>
      <Helmet>
        <title>{location.state?.jobdata?.seo_title || ""}</title>
        <meta
          name="description"
          content={location.state?.jobdata?.seo_meta_key || ""}
        />
        <meta
          name="Keywords"
          content={location.state?.jobdata?.seo_keywords || ""}
        />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="d-flex justify-content-end mt-4 me-5">
        <Link to="/" className="btn btn-info">
          Back
        </Link>
      </div>

      <div className="container mt-3 pb-5">
        {onLoad === undefined ? (
          <div className="text-center mt-5">
            <h2>Oops! This page does not exist.</h2>
            <p>Please check the URL or view other <Link to="/">job openings</Link>.</p>
          </div>
        ) : (
          <div className="row justify-content-center">
            <div id="col-one" className="col-xl-8 col-md-8">
              <div className="card card-profile">
                <div className="card-body pt-4">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="text-center">
                        <h2 className="h2">
                          {location.state
                            ? location.state.jobdata?.job_title
                            : onLoad && onLoad.job_title}
                        </h2>
                        <div className="h5 font-weight-300">
                          <i className="fas fa-map-marker-alt mr-2"></i>
                          {location.state
                            ? location.state.jobdata?.location
                            : onLoad && onLoad.job_location}
                        </div>
                      </div>
                      <div
                        className="card-profile-stats d-flex justify-content-center"
                        style={{
                          borderTop: "1px dashed #ccc",
                          borderBottom: "1px dashed #ccc",
                        }}
                      >
                        <div style={{ borderRight: "1px dashed #ccc" }}>

                          <span className="heading">1</span>
                          <span className="description">Vacancy</span>
                        </div>

                        <div>
                          <span className="heading">
                            {location.state
                              ? location.state.jobdata?.experience
                              : onLoad && onLoad.experience}
                          </span>
                          <span className="description">Experience</span>
                        </div>
                      </div>
                      <br />
                      {/* <p style={{ textAlign: "center" }}>
                      <strong>
                        <u>
                          {location.state
                            ? location.state.jobdata?.job_title
                            : onLoad && onLoad.job_title}
                        </u>
                      </strong>
                    </p> */}
                      {/* <p>
                      <strong>&nbsp;</strong>
                    </p> */}

                      <p>
                        <strong>Job Description:</strong>
                      </p>
                      <div
                        style={{ marginLeft: "35px" }}
                        id="jobRequirementsContainer"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: location.state
                              ? location.state.jobdata?.job_description
                              : onLoad && onLoad.job_description,
                          }}
                        />
                      </div>
                      <br />
                      <br />

                      <p>
                        <strong>Job Requirement:</strong>
                      </p>
                      <div id="jobRequirementsContainer">
                        <div
                          style={{ marginLeft: "35px" }}
                          dangerouslySetInnerHTML={{
                            __html: location.state
                              ? location.state.jobdata?.job_requirement
                              : onLoad && onLoad.job_requirement,
                          }}
                        />
                      </div>
                      <p>
                        <strong>&nbsp;</strong>
                      </p>
                      <p>&nbsp;</p>
                    </div>
                  </div>

                  <div
                    style={{ borderTop: "1px dashed #ccc" }}
                    className="d-flex justify-content-end pt-3 pe-4 mt-3"
                  >
                    <button
                      onClick={() => {
                        onStorageHandler(location.state?.jobdata || onLoad)
                        secureLocalStorage.setItem("login_status", 'form')
                      }}
                      type="button"
                      className="btn btn-info"
                      id="button-button-opensignup"
                      data-bs-toggle="modal"
                      data-bs-target="#signup"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-xl-4 col-md-4"
              style={{ height: firstColumnHeight, overflow: "hidden" }}
              ref={secondColumnRef}
            >
              <div
                className=""
                style={{
                  height: "100%",
                  background: "transparent",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="card card-profile">
                  <div className="card-body pt-4">
                    <div className="row">
                      <div className="col text-center">
                        <h2>Share with someone awesome</h2>
                        <a
                          className="social-icon"
                          href="https://facebook.com/sharer/sharer.php?u=https://career.fluidscapes.in/jobdetails?jobid=34&amp;quote=Fluidscapes Consultants (OPC) Pvt. Ltd. is hiring for Account/ Brand Manager  at https://career.fluidscapes.in/jobdetails?jobid=34"
                          target="_blank"
                          title="Share Job on Facebook"
                        >
                          <button
                            type="button"
                            id="socialbutton"
                            className="btn btn-facebook btn-icon-only rounded-circle"
                          >
                            <span className="btn-inner--icon">
                              <i className="fab fa-facebook"></i>
                            </span>
                          </button>
                        </a>

                        <a
                          className="social-icon"
                          href="https://www.instagram.com/fluidscapes1?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                          target="_blank"
                          title="Share Job on Instagram"
                        >
                          <button
                            type="button"
                            id="socialbutton"
                            className="btn btn-instagram btn-icon-only rounded-circle"
                          >
                            <span className="btn-inner--icon">
                              <i className="fab fa-instagram"></i>
                            </span>
                          </button>
                        </a>

                        <a
                          className="social-icon"
                          href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https://career.fluidscapes.in/jobdetails?jobid=34&amp;title=Fluidscapes Consultants (OPC) Pvt. Ltd. is hiring for Account/ Brand Manager &amp;summary=Fluidscapes Consultants (OPC) Pvt. Ltd. is hiring for Account/ Brand Manager at https://career.fluidscapes.in/jobdetails?jobid=34"
                          target="_blank"
                          title="Share Job on LinkedIn"
                        >
                          <button
                            type="button"
                            id="socialbutton"
                            className="btn btn-linkedin btn-icon-only rounded-circle"
                          >
                            <span className="btn-inner--icon">
                              <i className="fab fa-linkedin"></i>
                            </span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card card-profile pb-2 mt-0"
                  style={{
                    overflow: "hidden",
                    height: "inherit",
                    maxHeight: "inherit",
                    position: "relative",
                  }}
                >
                  <div id="col-two" className="card-body">
                    <div className="text-center">
                      <h2>Other Openings</h2>
                    </div>
                    <div
                      className="card-profile-stats justify-content-center"
                      style={{ borderTop: "1px dashed #ccc" }}
                    >
                      {rawJobPosting?.map((jobopen, index) => (
                        <div key={index} className="col-12">
                          <a
                            href="javacsript:void(0)"
                            onClick={() => onChangeDescription(jobopen)}
                            style={{ color: "#00A0E3" }}
                            className="title"
                          >
                            {jobopen.job_title || "-"}
                          </a>
                          <p className="description">{jobopen.location}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <div className="row pt-3" style={{ borderTop: "1px dashed #ccc" }}> */}
                  <div
                    className="d-flex justify-content-center me-3 view-all-btn"
                    style={{
                      position: "absolute",
                      width: "100%",
                      bottom: "10px",
                    }}
                  >
                    <Link
                      to="/"
                      className="btn btn-info"
                      id="button-button-jobopening"
                    >
                      View all Opening
                    </Link>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div >
    </>
  );
};

export default PageOpening;
